import React from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { Navbar as BootstrapNavbar, Nav } from "react-bootstrap";

const StyledNavbar = styled(BootstrapNavbar)`
  background-color: #2c3e50;
  padding: 20px 5%;

  .navbar-brand {
    font-size: 1.6rem;
    font-weight: 600;
    color: #ecf0f1;
    transition: color 0.3s ease;

    &:hover {
      color: #bdc3c7;
      text-decoration: none;
    }
  }

  .nav-link {
    margin-left: 25px;
    font-size: 1.1rem;
    font-weight: 500;
    color: #ecf0f1;
    transition: all 0.3s ease;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 0;
      height: 2px;
      background-color: #ecf0f1;
      transition: width 0.3s ease;
    }

    &.active::before,
    &:hover::before {
      width: 100%;
    }

    &:hover {
      color: #bdc3c7;
      text-decoration: none;
    }
  }

  .navbar-toggler {
    border-color: #ecf0f1;
  }
`;

const Navbar = () => {
  return (
    <StyledNavbar expand="lg">
      <StyledNavbar.Brand as={Link} to="/">
        Enes Doğan
      </StyledNavbar.Brand>
      <StyledNavbar.Toggle aria-controls="basic-navbar-nav" />
      <StyledNavbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link
            as={NavLink}
            to="/about"
            activeClassName="active"
            style={{ color: "#ecf0f1" }}
          >
            About
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            to="/skills"
            activeClassName="active"
            style={{ color: "#ecf0f1" }}
          >
            Technologies
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            to="/portfolio"
            activeClassName="active"
            style={{ color: "#ecf0f1" }}
          >
            Projects
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            to="/contact"
            activeClassName="active"
            style={{ color: "#ecf0f1" }}
          >
            Contact
          </Nav.Link>
        </Nav>
      </StyledNavbar.Collapse>
    </StyledNavbar>
  );
};

export default Navbar;
