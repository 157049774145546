import React from "react";
import styled from "styled-components";

const FooterContainer = styled.footer`
  bottom: 0;
  width: 100%;
  padding: 1rem 0;
  text-align: center;
  background-color:#f7f9fc;
`;

const FooterText = styled.p`
  margin: 0;
  color: #555;
`;

function Footer() {
  return (
    <FooterContainer>
      <FooterText>
        &copy; {new Date().getFullYear()} | ENES DOĞAN
      </FooterText>
    </FooterContainer>
  );
}

export default Footer;
