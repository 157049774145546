import React from "react";
import styled from "styled-components";
import { Button, Container, Row, Col, Card } from "react-bootstrap";
import {
  FaReact,
  FaNodeJs,
  FaDatabase,
  FaHtml5,
  FaCss3,
  FaBootstrap,
  FaJs,
  FaProjectDiagram,
  FaSass,
} from "react-icons/fa";
import profileImage from "../assets/me.png";

const Background = styled(Container)`
    padding: 3rem 0;
    background-color:#f7f9fc;
`;

const StyledProfileCard = styled(Card)`
  border: none;
  background: transparent;
`;

const ProfileCardBody = styled(Card.Body)`
  text-align: center;
`;

const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border: 4px solid #04364a;
`;

const Title = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  margin-top: 1rem;
  color: #2c3e50;
`;

const SubTitle = styled.h3`
  font-size: 1.6rem;
  margin-top: 1rem;
  margin-bottom: 1rem; // Bu satırı ekledik
  color: #34495e;
  border-bottom: 3px solid #64ccc5; // Bu satırı ekledik
  padding-bottom: 8px; // Bu satırı ekledik
`;

const Description = styled.p`
  font-size: 1.1rem;
  color: #7f8c8d;
`;

const TechIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #2980b9;
  margin: 0 20px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  span {
    margin-top: 0.5rem;
    font-weight: 500;
    color: #34495e;
  }
`;
const TechIconTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #34495e;
  border-bottom: 3px solid #64ccc5; // Bu satırı ekledik
  padding-bottom: 8px; // Bu satırı ekledik
`;

const ExternalIcon = styled.img`
  width: 50px;
  height: 50px;
`;

const Name = styled.h3`
  font-size: 1.8rem;
  margin-top: 0.5rem;
  color: #34495e;
  font-weight: 400;
`;

const StyledCard = styled(Card)`
  border-radius: 12px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  overflow: hidden;
`;

const StyledCardBody = styled(Card.Body)`
  padding: 1.5rem;
`;

const StyledButton = styled(Button)`
  background-color: #2980b9;
  border-color: #2980b9;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-weight: 600;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #2471a3;
    border-color: #2471a3;
  }

  svg {
    margin-right: 8px;
  }
`;

const ExperienceCard = styled.div`
  background: #f1f4f7;
  padding: 1rem 1.5rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
`;

const ExperienceTitle = styled.h4`
  font-size: 1.4rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
`;

const ExperienceDate = styled.span`
  font-size: 1rem;
  color: #7f8c8d;
  margin-bottom: 1rem;
  display: block;
`;

const ExperienceDescription = styled.p`
  font-size: 1.1rem;
  color: #34495e;
  margin-bottom: 0.5rem;
`;

const SkillCard = styled(Card)`
  border-radius: 12px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  overflow: hidden;
  padding: 1.5rem 0;
  margin: 1rem 0;
`;
const FaSassIcon = styled(FaSass)`
  color: hotpink;
`;
const FaHtml5Icon = styled(FaHtml5)`
  color: darkorange;
`;
const FaBootstrapIcon = styled(FaBootstrap)`
  color: rebeccapurple;
`;
const FaJavascriptIcon = styled(FaJs)`
  color: gold;
`;
const FaReactIcon = styled(FaReact)`
  color: #61dafb;
`;
const FaNodeJsIcon = styled(FaNodeJs)`
  color: #7eaa92;
`;

function About() {
  return (
    <Background fluid>
      <Container>
        <Row className="mb-5">
          <Col md={4}>
            <StyledProfileCard>
              <ProfileCardBody>
                <ProfileImage src={profileImage} alt="Profil Fotoğrafı" />
                <Title>Jr. Software Developer</Title>
                <Name>ENES DOĞAN</Name>
                <div className="mt-3">
                  <StyledButton variant="primary" href="/portfolio">
                    <FaProjectDiagram /> Projelerim
                  </StyledButton>
                </div>
              </ProfileCardBody>
            </StyledProfileCard>
          </Col>
          <Col md={8}>
            <StyledCard className="mb-3">
              <StyledCardBody>
                <SubTitle>Hakkımda</SubTitle>
                <Description>
                  Manisa Celal Bayar Üniversitesi 4. sınıf Bilgisayar
                  Mühendisliği öğrencisiyim.
                </Description>
              </StyledCardBody>
            </StyledCard>
            <StyledCard>
              <StyledCardBody>
                <SubTitle>Deneyimlerim</SubTitle>
                <ExperienceCard>
                  <ExperienceTitle>
                    Yazılım Stajyeri (.NET) - CeyBer
                  </ExperienceTitle>
                  <ExperienceDate>Haziran 2024 - Ağustos 2024</ExperienceDate>
                  <ExperienceDescription>
                    Backend alanında stajımı gerçekleştirdim <br />
                    <br />
                    <small>
                      <b> Kullanılan Teknolojiler:</b> 
                      .NET Core, Microsoft SQL Server, Entity Framework, ASP.NET Core MVC, ASP.NET Web API, Microsoft Excel, Bootstrap, JavaScript, jQuery, JSON, AJAX, Back-End Web Development, Testing
                    </small>
                  </ExperienceDescription>
                </ExperienceCard>

                <ExperienceCard>
                  <ExperienceTitle>
                    Yazılım Stajyeri (.NET) - Proktek Otomasyon
                  </ExperienceTitle>
                  <ExperienceDate>Ekim 2023 - Mayıs 2024</ExperienceDate>
                  <ExperienceDescription>
                    Backend alanında stajımı gerçekleştirdim <br />
                    <br />
                    <small>
                      <b> Kullanılan Teknolojiler:</b> C#, .NET, Entity
                      Framework, PostgreSQL, Vue.js, REST APIs,
                      Object-Relational Mapping (ORM), Postman
                    </small>
                  </ExperienceDescription>
                </ExperienceCard>

                <ExperienceCard>
                  <ExperienceTitle>Yazılım Stajyeri - Getucon</ExperienceTitle>
                  <ExperienceDate>Haziran 2023 - Ağustos 2023</ExperienceDate>
                  <ExperienceDescription>
                    Backend Alanında stajımı gerçekleştirdim.
                    <br /> <br />
                    <small><b>Kullanılan Teknolojiler:</b> PHP, Laravel, MySQL, Bootstrap, AJAX, jQuery, SASS, Git
                    </small>
                  </ExperienceDescription>
                </ExperienceCard>
                <ExperienceCard>
                  <ExperienceTitle></ExperienceTitle>
                  <ExperienceTitle>
                    IT ASİSTANI - MCBU Bilgi İşlem Departmanı
                  </ExperienceTitle>
                  <ExperienceDate>Ekim 2022 - Şubat 2023</ExperienceDate>
                  <ExperienceDescription>
                    Okulumuzun IT bölümünde sonbahar döneminde yarı zamanlı
                    öğrenci olarak çalıştım.
                  </ExperienceDescription>
                </ExperienceCard>
              </StyledCardBody>
            </StyledCard>
          </Col>
        </Row>
        <Row className="text-center mb-5">
          <Col xs={12}>
            <SkillCard>
              <TechIconTitle>Diller ve Araçlar</TechIconTitle>
              <Row>
                <Col xs={4} sm={4} md={3}>
                  <TechIcon>
                    <FaHtml5Icon size={50} />
                    <span>HTML5</span>
                  </TechIcon>
                </Col>
                <Col xs={4} sm={4} md={3}>
                  <TechIcon>
                    <FaCss3 size={50} />
                    <span>CSS3</span>
                  </TechIcon>
                </Col>
                <Col xs={4} sm={4} md={3}>
                  <TechIcon>
                    <FaSassIcon size={50} />
                    <span>Sass</span>
                  </TechIcon>
                </Col>
                <Col xs={4} sm={4} md={3}>
                  <TechIcon>
                    <FaBootstrapIcon size={50} />
                    <span>Bootstrap</span>
                  </TechIcon>
                </Col>
                <Col xs={4} sm={4} md={3}>
                  <TechIcon>
                    <FaJavascriptIcon size={50} />
                    <span>JavaScript</span>
                  </TechIcon>
                </Col>
                <Col xs={4} sm={4} md={3}>
                  <TechIcon>
                    <ExternalIcon src="https://raw.githubusercontent.com/devicons/devicon/master/icons/jquery/jquery-original-wordmark.svg" />
                    <span>jQuery</span>
                  </TechIcon>
                </Col>
                <Col xs={4} sm={4} md={3}>
                  <TechIcon>
                    <FaReactIcon size={50} />
                    <span>React</span>
                  </TechIcon>
                </Col>
                <Col xs={4} sm={4} md={3}>
                  <TechIcon>
                    <FaNodeJsIcon size={50} />
                    <span>Node.js</span>
                  </TechIcon>
                </Col>
                <Col xs={4} sm={4} md={3}>
                  <TechIcon>
                    <ExternalIcon src="https://raw.githubusercontent.com/devicons/devicon/master/icons/express/express-original-wordmark.svg" />
                    <span>Express.js</span>
                  </TechIcon>
                </Col>
                <Col xs={4} sm={4} md={3}>
                  <TechIcon>
                    <FaDatabase size={50} />
                    <span>Database</span>
                  </TechIcon>
                </Col>

                <Col xs={4} sm={4} md={3}>
                  <TechIcon>
                    <ExternalIcon src="https://raw.githubusercontent.com/devicons/devicon/master/icons/csharp/csharp-original.svg" />
                    <span>C#</span>
                  </TechIcon>
                </Col>
                <Col xs={4} sm={4} md={3}>
                  <TechIcon>
                    <ExternalIcon src="https://raw.githubusercontent.com/devicons/devicon/master/icons/dot-net/dot-net-original-wordmark.svg" />
                    <span>.NET</span>
                  </TechIcon>
                </Col>
              </Row>
            </SkillCard>
          </Col>
        </Row>
      </Container>
    </Background>
  );
}

export default About;
