import React from "react";
import styled from "styled-components";
import certificateImage from "./certificate.jpg";

const EducationTimeline = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 60px 0;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 6px;
    background: #273c75; // Koyu mavi
  }
`;

const EducationCard = styled.article`
  background: #dcdde1; // Soft gray
  border-radius: 25px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
  margin: 35px 0;
  width: 48%; // Give a little more width
  padding: 35px; // More spacing
  position: relative;
  transition: all 0.4s ease-out;

  &:nth-child(odd) {
    margin-left: 52%;
  }

  &:nth-child(even) {
    margin-right: 52%;
  }

  &:hover {
    transform: translateY(-20px);
    box-shadow: 0 12px 28px rgba(0, 0, 0, 0.15);
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #64ccc5; // Red accent
    transform: translateY(-50%);
    z-index: 1;
    border: 5px solid #dcdde1;
  }

  &:nth-child(odd)::before {
    left: -12.5px;
  }

  &:nth-child(even)::before {
    right: -12.5px;
  }

  strong {
    font-size: 1.4em;
    color: #192a56; // Deep blue
    margin-bottom: 10px; // Spacing
  }

  p {
    margin-bottom: 8px;
    color: #353b48; // Grayish blue
    font-size: 1.1em;
  }

  span {
    font-weight: 500;
    color: #273c75; // Deep blue
  }
`;

const CertificateCard = styled(EducationCard)`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    border-radius: 25px;
    width: 95%;
    margin: 25px 0;
    transition: transform 0.3s ease;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);

    &:hover {
      transform: scale(1.08);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    }
  }

  &::before {
    content: none;
  }
`;

function Education() {
  return (
    <EducationTimeline>
      <EducationCard>
        <strong>Manisa Celal Bayar Üniversitesi</strong>
        <p>Bilgisayar Mühendisliği</p>
        <span>2021-devam ediyor...</span>
      </EducationCard>
      <CertificateCard>
        <strong>Web Geliştirme Eğitimi Sertifikası</strong>
        <img src={certificateImage} alt="Frontend Eğitimi" />
      </CertificateCard>
    </EducationTimeline>
  );
}

export default Education;
