// src/pages/NotFound.js

import React from "react";
import { Link } from "react-router-dom";
import { FiHome } from "react-icons/fi"; // Ana sayfa simgesi için React Icons'dan FiHome'u içe aktar
import styled from "styled-components";

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh; // Sayfa boyutunu kaplaması için
  text-align: center;
`;

const Message = styled.p`
  font-size: 1.5rem;
`;

const HomeButton = styled(Link)`
  display: flex;
  align-items: center;
  background-color: #007bff; // Bootstrap primar rengi
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 20px;

  &:hover {
    background-color: #0056b3; // Bootstrap hover rengi
  }
`;

const NotFound = () => {
  return (
    <NotFoundContainer>
      <h1>404 - Sayfa Bulunamadı</h1>
      <Message>Aradığınız sayfa mevcut değil veya taşınmış olabilir.</Message>
      <HomeButton to="/">
        <FiHome style={{ marginRight: "5px" }} /> Ana Sayfaya Dön
      </HomeButton>
    </NotFoundContainer>
  );
};

export default NotFound;
