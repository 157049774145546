import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ProgressBar } from "react-bootstrap";
import {
  FaReact,
  FaHtml5,
  FaNodeJs,
  FaDatabase,
  FaCss3,
  FaSass,
  FaBootstrap,
  FaJs
} from "react-icons/fa";

const SkillContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 60px 0;
  background-color: #f7f9fc;
`;

const Header = styled.h1`
  font-size: 2.5em;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 40px;
  text-align: center;
`;

const SkillCard = styled.article`
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.1);
  padding: 40px;
  margin: 15px 0;
  width: 80%;
  max-width: 800px; // Ensure responsiveness
  transition: all 0.3s ease-out;

  &:hover {
    transform: translateY(-7px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.08), 0 10px 10px rgba(0, 0, 0, 0.12);
  }
`;

const SkillTitle = styled.h4`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
  font-weight: 600;
  color: #34495e;
  font-size: 1.6em;
`;

const StyledProgressBar = styled(ProgressBar)`
  height: 28px;
  border-radius: 14px;
  background-color: #ecf0f1; // Added a background for unfilled progress

  .progress-bar {
    background-color: #3498db;
    border-radius: 14px;
    transition: width 1.5s ease-in-out; // Smoother transition for progress fill
  }
`;

const Skills = () => {
  const [filled, setFilled] = useState(0);
  const skills = [
    {
      id: 1,
      icon: <FaHtml5 size={32} color="darkorange" />,
      name: "HTML5",
      proficiency: 90
    },
    {
      id: 2,
      icon: <FaCss3 size={32} color="#2980b9" />,
      name: "CSS3",
      proficiency: 90
    },
    {
      id: 3,
      icon: <FaSass size={32} color="hotpink" />,
      name: "Sass",
      proficiency: 70
    },
    {
      id: 4,
      icon: <FaBootstrap size={32} color="rebeccapurple" />,
      name: "Bootstrap",
      proficiency: 75
    },
    {
      id: 5,
      icon: <FaJs size={32} color="gold" />,
      name: "JavaScript",
      proficiency: 80
    },
    {
      id: 6,
      icon: (
        <img
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/jquery/jquery-original-wordmark.svg"
          alt="jQuery"
          width="32"
        />
      ),
      name: "jQuery",
      proficiency: 70 // Set your proficiency level
    },
    {
      id: 7,
      icon: <FaReact size={32} color="#61dafb" />,
      name: "React.js",
      proficiency: 80 // Set your proficiency level
    },
    ,
    {
      id: 8,
      icon: <FaNodeJs size={32} color="#7eaa92" />,
      name: "Node.js",
      proficiency: 70 // Set your proficiency level
    },
    {
      id: 9,
      icon: (
        <img
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/express/express-original-wordmark.svg"
          alt="Express.js"
          width="32"
        />
      ),
      name: "Express.js",
      proficiency: 70 // Set your proficiency level
    },
    // Assuming FaDatabase is a placeholder for a database icon. Replace with actual icon if available.
    {
      id: 10,
      icon: <FaDatabase size={32} />, // This is a placeholder. You might need to use an actual database icon here.
      name: "SQL",
      proficiency: 75 // Set your proficiency level
    },
    {
      id: 11,
      icon: (
        <img
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/dot-net/dot-net-original-wordmark.svg"
          alt=".NET"
          width="32"
        />
      ),
      name: ".NET",
      proficiency: 70 // Set your proficiency level
    },
    {
      id: 12,
      icon: (
        <img
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/csharp/csharp-original.svg"
          alt="C#"
          width="32"
        />
      ),
      name: "C#",
      proficiency: 75 // Set your proficiency level
    }
  ];

  useEffect(() => {
    const timer = setTimeout(() => setFilled(1), 300);
    return () => clearTimeout(timer);
  }, []);

  return (
    <SkillContainer>
      <Header>Diller ve Araçlar</Header>
      {skills.map((skill) => (
        <SkillCard key={skill.id}>
          <SkillTitle>
            {skill.icon} {skill.name}
          </SkillTitle>
          <StyledProgressBar
            now={filled * skill.proficiency}
            label={`${skill.proficiency}%`}
          />
        </SkillCard>
      ))}
    </SkillContainer>
  );
};

export default Skills;
