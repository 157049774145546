// Contact.js

import React from "react";
import styled from "styled-components";
import { FaGithub, FaMedium, FaLinkedin } from "react-icons/fa"; // Gerekli ikonları içe aktarıyoruz.
import profileImage from "../assets/me.png";

const ContactContainer = styled.section`
  min-height: 100vh;
  background-color: #f7f9fc;
`;

const MainCard = styled.main`
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 20px 0;
`;

const Card = styled.article`
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  display: flex;
  align-items: center;
  gap: 20px;
`;

const ProfileImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
`;

const IconLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const IconLink = styled.a`
  font-size: 24px;
  color: #2c3e50;
  transition: color 0.2s;

  &:hover {
    color: #007bff; // Bootstrap hover renk değeri
  }
`;

const Contact = () => {
  return (
    <ContactContainer>
      <div className="container mt-3">
        <MainCard>
          <Card>
            <ProfileImage src={profileImage} alt="Enes Doğan" />
            <div>
              <h2>Enes Doğan</h2>
              <p>Jr. Software Developer</p>
            </div>
          </Card>
          <Card>
            <IconLinks>
              <IconLink
                href="https://linkedin.com/in/doganenes"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin />
              </IconLink>
              <IconLink
                href="https://github.com/doganenes"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaGithub />
              </IconLink>
              <IconLink
                href="https://medium.com/@enesdogann"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaMedium />
              </IconLink>
            </IconLinks>
          </Card>
        </MainCard>
      </div>
    </ContactContainer>
  );
};

export default Contact;
