import React from "react";
import styled from "styled-components";
import { FaReact, FaNodeJs, FaDatabase, FaGit } from "react-icons/fa";
import profileImage from "../assets/me.png";

const DotNetIcon = styled.div`
  background: url("https://raw.githubusercontent.com/devicons/devicon/master/icons/dot-net/dot-net-original-wordmark.svg");
  width: 34px;
  height: 34px;
  background-size: cover;
  gap: 20px;
  margin-top: 10px;
`;

const CSharpIcon = styled.div`
  background: url("https://raw.githubusercontent.com/devicons/devicon/master/icons/csharp/csharp-original.svg");
  width: 34px;
  height: 34px;
  background-size: cover;
  gap: 20px;
  margin-top: 10px;
`;

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  min-height: 100vh;
  background-color: #F7F9FC;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
`;
const ProfileImage = styled.img`
  width: 160px;
  height: 160px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #04364a;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
`;

const Title = styled.h1`
  margin-top: 20px;
  font-size: 26px;
  color: #04364a;
`;

const TechStack = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`;

const Icon = styled.div`
  font-size: 34px;
  color: #04364a;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const Home = () => {
  return (
    <HomeContainer>
      <ProfileImage src={profileImage} alt="Profile" />
      <Title>ENES DOĞAN</Title>
      <Title>Jr. Software Developer</Title>
      <TechStack>
        <Icon>
          <CSharpIcon />
        </Icon>
        <Icon>
          <DotNetIcon />
        </Icon>
        <Icon>
          <FaDatabase />
        </Icon>
        <Icon>
          <FaReact />
        </Icon>
        <Icon>
          <FaNodeJs />
        </Icon>
        <Icon>
          <FaGit />
        </Icon>
      </TechStack>
    </HomeContainer>
  );
};

export default Home;
