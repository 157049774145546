import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Card, Button, Container, Row, Col } from "react-bootstrap";

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  background-color: #f7f9fc;

  h2 {
    margin-top:1rem;
    margin-bottom: 1.5rem;
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
  }
`;

const StyledCard = styled(Card)`
  border-radius: 10px;
  margin: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const StyledButton = styled(Button)`
  margin-top: 1rem;
`;

const Portfolio = () => {
  const [projects, setProjects] = useState([]);
  const username = "doganenes";

  useEffect(() => {
    const fetchRepositories = async () => {
      try {
        const response = await fetch(
          `https://api.github.com/users/${username}/repos`
        );
        const data = await response.json();

        // Sort by created_at in descending order (most recent first)
        data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

        const repos = data
          .slice(0, 8) // Slice the array to keep only the first 8 items (after sorting)
          .map((repo) => ({
            id: repo.id,
            title: repo.name,
            description: repo.description || "Description not provided.",
            githubLink: repo.html_url,
          }));

        setProjects(repos);
      } catch (error) {
        console.error("Error fetching repositories:", error);
      }
    };

    fetchRepositories();
  }, []);

  return (
    <StyledContainer>
      <h2>Projelerim</h2>
      <Row>
        {projects.map((project) => (
          <Col lg={4} md={6} sm={12} className="mb-4" key={project.id}>
            <StyledCard>
              {/* Removed the Card.Img component */}
              <Card.Body>
                <Card.Title>{project.title}</Card.Title>
                <Card.Text>{project.description}</Card.Text>
                <StyledButton
                  variant="dark"
                  href={project.githubLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Proje GitHub Linki
                </StyledButton>
              </Card.Body>
            </StyledCard>
          </Col>
        ))}
      </Row>
    </StyledContainer>
  );
};

export default Portfolio;
